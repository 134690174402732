import React, { useState } from "react";
import logo from "../../assets/LEFTKOAST-LOGO.png";
import ReCAPTCHA from "react-google-recaptcha";
import { PiTrashThin } from "react-icons/pi";
import { useSelector, useDispatch } from "react-redux";
import { setCartItems } from "../../redux/slices/cartItems";
import { setToggleCart } from "../../redux/slices/toggleCart";
import { setSuccessMessage } from "../../redux/slices/successMessage";
import { setErrorMessage } from "../../redux/slices/errorMessage";
import { IoCloseOutline } from "react-icons/io5";
import { loadStripe } from "@stripe/stripe-js";
import "./Cart.css";

function Cart() {
  const cartItems = useSelector((state) => state.cartItems.cartItems);
  const toggleCart = useSelector((state) => state.toggleCart.toggleCart);
  const user = useSelector((state) => state.user.user);
  const production = useSelector((state) => state.production.production);
  const testingURL = "http://127.0.0.1:5000/create-checkout-session";
  const productionURL = "https://lkp-api.vercel.app/create-checkout-session";
  const [stripeError, setStripeError] = useState(null);
  const [capVal, setCapVal] = useState(null);
  const dispatch = useDispatch();

  // Stripe initialization with your publishable key
  const stripePromise = loadStripe(
    "pk_live_51PVblCFhNDvgGzPkOV3qskLp9G8dnx0w1NWQqq5l3xMRLvnQug8sWE36KxlXggzZwQig8GMFtELTvqQwAylE7mzD00RVc2viPm"
  );

  // Calculate total price of cart items
  const calculateTotal = () => {
    let total = 0;
    cartItems.forEach((item) => {
      total += Number(item.default_price.unit_amount_decimal);
    });
    return total / 100; // Convert total from cents to dollars if necessary
  };

  // Handle form submission for Stripe checkout
  const handleFormSubmit = async (event) => {
    event.preventDefault();

    if (!user) {
      dispatch(setErrorMessage("Please log in to proceed with checkout."));
      return;
    }

    try {
      const response = await fetch(production ? productionURL : testingURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          cartItems: cartItems.map((item) => ({
            priceID: item.default_price.id,
          })),
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to create checkout session");
      }

      const data = await response.json();

      const stripe = await stripePromise;
      const { error } = await stripe.redirectToCheckout({
        sessionId: data.sessionId,
      });

      if (error) {
        setStripeError(error.message);
      } else {
        // Handle the logic after Stripe checkout completes
        dispatch(setSuccessMessage("Stripe checkout completed"));
      }
    } catch (error) {
      console.error("Error creating checkout session:", error);
      // Handle error as needed
      setStripeError("Error processing payment. Please try again later.");
    }
  };

  // Handle emptying the cart
  const handleEmptyCart = () => {
    dispatch(setCartItems([])); // Clear cart items in Redux store
    dispatch(setSuccessMessage("Cart has been emptied."));
    localStorage.removeItem("cartItems"); // Remove cart items from local storage
  };

  const handleCloseCart = () => {
    dispatch(setToggleCart(false)); // Close the cart
  };
  
  const handleDeleteItemInCart = (id) => {
    if(!cartItems.length) return
    const newCart = cartItems.filter(item => item.id !== id)
    dispatch(setCartItems(newCart));
    localStorage.setItem('cartItems', JSON.stringify(newCart))
  }

  return (
    <div className="Cart" style={{ zIndex: toggleCart ? 99999 : -1 }}>
      <div
        className="cart-items"
        style={{ right: toggleCart ? "0" : "-400px" }}
      >
        <IoCloseOutline
          style={{ position: "absolute", right: "0", cursor: "pointer" }}
          onClick={handleCloseCart}
          size={28}
        />
        <img src={logo} width={100} alt="logo" />
        <span>Cart issues? Try refreshing your browser.</span>
        <div className="cart-items-wrapper">
          {cartItems.map((item) => (
            <div className="item-container" key={item.id}>
              <img src={item.images[0]} alt={item.title} width={75} />
              <div className="item-desc">
                <p>{item.name}</p>
                <p className="price">
                  $
                  {Number(item.default_price.unit_amount_decimal / 100).toFixed(
                    2
                  )}
                </p>
                <PiTrashThin size={24} onClick={() => handleDeleteItemInCart(item.id)}/>
              </div>
            </div>
          ))}
        </div>

        <div
          className="checkout-container"
          style={{ zIndex: toggleCart ? 2 : -1 }}
        >
          <div className="total">
            <h4>Subtotal ({cartItems.length} items)</h4>
            <h4>${calculateTotal().toFixed(2)}</h4>
          </div>
          <ReCAPTCHA
            sitekey="6Lc3DQ0qAAAAAGqF6SVufDCzjO5NcryMUpqUTSKQ"
            onChange={(val) => setCapVal(val)}
          />
          <form onSubmit={handleFormSubmit}>
            <button disabled={!capVal || !cartItems.length} type="submit">
              CHECKOUT
            </button>
          </form>
          <button onClick={handleEmptyCart} disabled={!cartItems.length}>EMPTY CART</button>
          {stripeError && <div className="error-message">{stripeError}</div>}
        </div>
      </div>
    </div>
  );
}

export default Cart;
