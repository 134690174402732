import React, { useState } from "react";
import logo from "../../assets/LEFTKOAST-LOGO.png";
import ReCAPTCHA from "react-google-recaptcha";
import DOMPurify from "dompurify";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../../redux/slices/user";
import { setLibrary } from "../../redux/slices/library";
import { setErrorMessage } from "../../redux/slices/errorMessage";
import * as Yup from "yup";
import "./SignIn.css";

function SignIn() {
  const production = useSelector((state) => state.production.production);
  const [capVal, setCapVal] = useState(null);
  const testingURL = "http://127.0.0.1:5000/login";
  const productionURL = "https://lkp-api.vercel.app/login";
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Validation schema using Yup
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  // Formik hook for managing form state and validation
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const sanitizedEmail = DOMPurify.sanitize(values.email);
      const sanitizedPassword = DOMPurify.sanitize(values.password);

      try {
        // Simulate login API call (replace with actual login logic)
        const response = await fetch(production ? productionURL : testingURL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: sanitizedEmail,
            password: sanitizedPassword,
          }),
        });

        if (!response.ok) {
          if (response.status === 401) {
            dispatch(setErrorMessage("Invalid email or password"));
          } else {
            dispatch(setErrorMessage("Network response was not ok"));
          }
        } else {
          const data = await response.json();
          localStorage.setItem("token", data.token);
          localStorage.setItem("user", JSON.stringify(data));
          localStorage.setItem("library", JSON.stringify(data.user.libraries));
          dispatch(setUser(data)); // Dispatch action to set user in Redux store
          dispatch(setLibrary(data.user.libraries));
          navigate("/"); // Redirect to home page after successful login
        }
      } catch (error) {
        console.error("Error logging in:", error.message);
        // Handle error appropriately (e.g., display error message)
      }
    },
  });

  return (
    <div className="SignIn">
      <div className="sign-in-wrapper">
        <Link to="/">
          <img src={logo} width={150} alt="logo" />
        </Link>
        <form onSubmit={formik.handleSubmit}>
          <h3>Sign in to continue</h3>
          <p>
            Sign in to view your LKP account. If you don't have one, you will be
            prompted to create one.
          </p>
          <input
            type="text"
            name="email"
            onChange={formik.handleChange}
            value={formik.values.email}
            placeholder="Email"
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="error-message">{formik.errors.email}</div>
          ) : null}
          <input
            type="password"
            name="password"
            onChange={formik.handleChange}
            value={formik.values.password}
            placeholder="Password"
          />
          {formik.touched.password && formik.errors.password ? (
            <div className="error-message">{formik.errors.password}</div>
          ) : null}
          <ReCAPTCHA
            sitekey="6Lc3DQ0qAAAAAGqF6SVufDCzjO5NcryMUpqUTSKQ"
            onChange={(val) => setCapVal(val)}
          />
          <button disabled={!capVal} className={!capVal ? "btn-disabled" : ""} type="submit">
            LOGIN
          </button>
          <Link to="/reset-password">Forgot Password?</Link>
          <p>
            Don't have an account? <Link to="/signup">Sign up</Link>
          </p>
        </form>
      </div>
    </div>
  );
}

export default SignIn;
