import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { setProductGenre } from "../../redux/slices/productGenre";
import { Link } from "react-router-dom";
import {
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaPinterest,
} from "react-icons/fa";
import { GoTriangleUp, GoTriangleDown } from "react-icons/go";

import "./Footer.css";

function Footer() {
  const [toggleBrowser, setToggleBrowser] = useState(false);
  const dispatch = useDispatch();
  // Assuming genres are fetched from an API or a static array
  const genres = [
    { genre: "Horror" },
    { genre: "Fiction" },
    { genre: "Non-Fiction" },
    { genre: "Thriller" },
    { genre: "Mystery" },
    { genre: "Romance" },
    { genre: "Sci-Fi" },
    { genre: "Adventure" },
    { genre: "Drama" },
    { genre: "Children's" },
    { genre: "Biography" },
    { genre: "Cooking" },
    { genre: "History" },
    { genre: "Education" },
    { genre: "Sports" },
    { genre: "Business" },
    { genre: "Art" },
    { genre: "Technology" },
    { genre: "Poetry" },
    { genre: "Philosophy" },
  ];

  const handleViewByGenre = (genre) => {
    dispatch(setProductGenre(genre));
  };

  const handleToggleBrowser = () => {
    setToggleBrowser(!toggleBrowser);
  };

  return (
    <div className="Footer">
      <div className="footer-wrapper">
        <div className="browser">
          <div className="footer-header">
            <h4>BROWSER</h4>
            <button className="toggle" onClick={handleToggleBrowser}>
              {toggleBrowser ? <GoTriangleUp /> : <GoTriangleDown />}
            </button>
          </div>
          <div
            className={`browser-categories ${toggleBrowser ? "expanded" : ""}`}
          >
            {genres.map((book) => (
              <button
                key={book.genre}
                onClick={() => handleViewByGenre(book.genre)}
              >
                {book.genre}
              </button>
            ))}
          </div>
        </div>
        <div className="help">
          <h4>HELP</h4>
          <Link to="/terms-and-conditions">Terms and Conditions</Link>
          <Link to="/privacy-policy">Privacy Policy</Link>
          <Link to="/refund-policy">Refund Policy</Link>
          <Link to="https://forms.gle/CEj1tW1tQNDYGj5w5">eBook Form</Link>
        </div>
        <div className="about">
          <h4>ABOUT US</h4>
          <Link to="/submissions">Submissions</Link>
          <Link to="/contact">Contact Us</Link>
        </div>
      </div>
      <hr />
      <div className="footer-bottom">
        <div className="footer-right">
          <ul>
            <li>
              <Link to="https://www.facebook.com/leftkoast">
                <FaFacebookF color="white" />
              </Link>
            </li>
            <li>
              <Link to="https://x.com/left_koast">
                <FaTwitter color="white" />
              </Link>
            </li>
            <li>
              <Link to="https://www.instagram.com/left_koast_productions/">
                <FaInstagram color="white" />
              </Link>
            </li>
            <li>
              <Link to="https://www.pinterest.com/LeftKoastProductions/">
                <FaPinterest color="white" />
              </Link>
            </li>
          </ul>
          <p>© 2024 LKP. All rights reserved.</p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
