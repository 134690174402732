import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ReCAPTCHA from "react-google-recaptcha";
import { Link, useNavigate } from "react-router-dom";
import { setUser } from "../../redux/slices/user";
import { setCartItems } from "../../redux/slices/cartItems";
import { setLibrary } from "../../redux/slices/library";
import { setSuccessMessage } from "../../redux/slices/successMessage";
import { setErrorMessage } from "../../redux/slices/errorMessage";
import { useFormik } from "formik";
import * as Yup from "yup";
import "./Account.css";

function Account() {
  const user = useSelector((state) => state.user.user);
  const production = useSelector((state) => state.production.production);
  const [editUser, setEditUser] = useState(false);
  const [editPassword, setEditPassword] = useState(false);
  const [deleteAccount, setDeleteAccount] = useState(false);
  const [capVal, setCapVal] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Ensure user is available
  useEffect(() => {
    if (!user) {
      navigate("/");
    }
  }, [user, navigate]);

  // URLs based on environment
  const apiURL = production
    ? `https://lkp-api.vercel.app/user/${user?.user.id}`
    : `http://127.0.0.1:5000/user/${user?.user.id}`;

  // Handle sign out
  const handleSignOut = () => {
    // Clear user data from Redux store
    dispatch(setUser(null));
    dispatch(setCartItems([])); // Clear cart items
    dispatch(setLibrary([])); // Clear library items

    // Clear token and user data from local storage
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("library");
    localStorage.removeItem("cartItems");

    // Navigate to home page
    navigate("/");

    console.log("User signed out");
  };

  // Form validation schema
  const validationSchema = Yup.object().shape({
    fname: Yup.string(),
    lname: Yup.string(),
    email: Yup.string().email("Invalid email"),
    user_role: Yup.string(),
    _password_hash: Yup.string(),
  });

  // Formik setup
  const formik = useFormik({
    initialValues: {
      fname: user?.user.fname,
      lname: user?.user.lname,
      email: user?.user?.email,
      user_role: user?.user.user_role,
      _password_hash: user?.user?.password_hash,
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        setCapVal(null)
        const response = await fetch(apiURL, {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.access_token}`,
          },
          body: JSON.stringify({
            ...values,
          }),
        });

        if (!response.ok) {
          throw new Error(
            `Network response was not ok: ${response.statusText}`
          );
        }
        dispatch(
          setSuccessMessage("User data updated successfully. Logging out…")
        );
        dispatch(setUser(null));
        dispatch(setCartItems([])); // Clear cart items
        dispatch(setLibrary([])); // Clear library items

        // Clear token and user data from local storage
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        localStorage.removeItem("library");
        localStorage.removeItem("cartItems");

        // Navigate to home page
        navigate("/");
        setEditUser(false);
      } catch (error) {
        dispatch(setErrorMessage("Error updating user, please try again."));
        console.error("Error updating user:", error);
      }
    },
  });

  const handleAccountDelete = async () => {
    try {
      const response = await fetch(apiURL, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.access_token}`,
        },
      });

      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
      dispatch(setSuccessMessage("User deleted successfully. Logging out…"));
      dispatch(setUser(null));
      dispatch(setCartItems([])); // Clear cart items
      dispatch(setLibrary([])); // Clear library items

      // Clear token and user data from local storage
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      localStorage.removeItem("library");
      localStorage.removeItem("cartItems");

      // Navigate to home page
      navigate("/");

      // Optionally close or reset the form
      setEditUser(false);
    } catch (error) {
      console.error("Error deleting user:", error);
    }
  };

  // If user is not present, redirect
  if (!user) {
    return null;
  }

  return (
    <div className="Account">
      <div className="account-wrapper">
        <div className="account-details">
          <div className="details">
            <h1>Account Details</h1>
            <h3>
              {user.user.user_role === "ADMIN" ||
              user.user.user_role === "AUTHOR"
                ? `ID: ${user.user.id}`
                : ""}
            </h3>
            <form
              onSubmit={formik.handleSubmit}
              className={editUser ? "active-form" : ""}
            >
              <div className="user-name">
                <input
                  type="text"
                  name="fname"
                  placeholder={user.user.fname}
                  value={formik.values.fname}
                  disabled={!editUser}
                  onChange={formik.handleChange}
                />
                <input
                  type="text"
                  name="lname"
                  placeholder={user.user.lname}
                  value={formik.values.lname}
                  disabled={!editUser}
                  onChange={formik.handleChange}
                />
              </div>
              <div className="user-info">
                <input
                  type="email"
                  name="email"
                  placeholder={user.user.email}
                  value={formik.values.email}
                  disabled={!editUser}
                  onChange={formik.handleChange}
                />
              </div>
              <h3>{user?.user.user_role}</h3>
              {editPassword && (
                <input
                  type="password"
                  name="_password_hash"
                  placeholder="New Password"
                  value={formik.values._password_hash}
                  onChange={formik.handleChange}
                />
              )}
              {(editUser || editPassword) && (
                <div className="edit-buttons">
                  <button type="submit" disabled={!capVal}>
                    SAVE
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      setEditUser(false);
                      setEditPassword(false);
                      setCapVal(null)
                    }}
                  >
                    CANCEL
                  </button>
                  <ReCAPTCHA
                    sitekey="6Lc3DQ0qAAAAAGqF6SVufDCzjO5NcryMUpqUTSKQ"
                    onChange={(val) => setCapVal(val)}
                  />
                </div>
              )}
            </form>
            <div className="account-buttons">
              {/* {user.user.user_role === "AUTHOR" ||
                (user.user.user_role === "ADMIN" && (
                  <Link to="/dashboard">DASHBOARD</Link>
                ))} */}
              <Link
                className={editUser || editPassword ? "disabled" : ""}
                onClick={() => setEditUser(true)}
                to="#"
              >
                EDIT INFO
              </Link>
              <Link
                className={editUser || editPassword ? "disabled" : ""}
                onClick={() => setEditPassword(true)}
                to="#"
              >
                CHANGE PASSWORD
              </Link>
              <Link
                className={editUser || editPassword ? "disabled" : ""}
                onClick={() => setDeleteAccount(true)}
                to="#"
              >
                DELETE ACCOUNT
              </Link>
              <Link
                className={editUser || editPassword ? "disabled" : ""}
                onClick={handleSignOut}
                to="#"
              >
                SIGN OUT
              </Link>
            </div>
          </div>
          <div className="order-history">{/* <h1>Order History</h1> */}</div>
        </div>
      </div>
      {deleteAccount && (
        <div className="delete-account">
          <div className="delete-account-wrapper">
            <h1>Delete Account</h1>
            <p>
              Are you sure you want to delete your account? This action cannot
              be undone.
            </p>
            <div className="delete-buttons">
              <button onClick={handleAccountDelete}>DELETE ACCOUNT</button>
              <button onClick={() => setDeleteAccount(false)}>CANCEL</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Account;
