import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setProductID } from "../../redux/slices/productID";
import { IoCloseOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import "./ProductByID.css";
import SpinnerLoader from "../SpinnerLoader/SpinnerLoader";
import { setErrorMessage } from "../../redux/slices/errorMessage";

function ProductByID({ addToCart }) {
  const user = useSelector((state) => state.user.user);
  const productID = useSelector((state) => state.productID.productID);
  const library = useSelector((state) => state.library.library);
  const production = useSelector((state) => state.production.production);
  const testingURL = `http://127.0.0.1:5000/products/${productID}`;
  const productionURL = `https://lkp-api.vercel.app/products/${productID}`;
  const [productDataByID, setProductDataByID] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isBought = library?.some((item) => item.product_id === productID);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch(production ? productionURL : testingURL);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setProductDataByID(data);
      } catch (error) {
        console.error("Error fetching products:", error.message);
        dispatch(setErrorMessage("Error get details product."))
        removeDetails()
      }
    };

    fetchProducts();
  }, [productID]);

  const removeDetails = () => {
    dispatch(setProductID(null));
  };

  const handlePurchasedBook = () => {
    // Save productID to localStorage
    localStorage.setItem("productid", JSON.stringify(productID));
    dispatch(setProductID(productID));
    navigate(`/product/${productID}`);
  };

  return (
    <div className="ProductByID">
      {productDataByID.id ? (
        <div
          className="product-by-id-wrapper"
          style={productDataByID.metadata?.book_cover ? {
            backgroundImage: `url(${productDataByID.metadata?.book_cover})`,
          } : {}}
        >
          <IoCloseOutline onClick={removeDetails} size={36} />
          <img src={productDataByID.metadata?.book_logo} alt="Book Logo" />
          <p>{productDataByID.description}</p>
          <p>
            Price: $
            {Number(
              productDataByID?.default_price?.unit_amount_decimal / 100
            ).toFixed(2)}
          </p>
          <div className="product-by-id-btn-wrapper">
            {isBought || user?.user?.user_role === "ADMIN" ? (
              // Render this block if the book is already bought
              <button onClick={handlePurchasedBook}>READ NOW</button>
            ) : // Render this block if the book is not bought
            productDataByID?.metadata?.iframe_src ? (
              // Render "ADD TO CART" button if iframeURL metadata exists
              <button onClick={() => addToCart(productDataByID)}>
                ADD TO CART
              </button>
            ) : (
              // Render a disabled button if iframeURL metadata does not exist
              <button disabled>COMING SOON</button>
            )}
            <button>DETAILS</button>
          </div>
        </div>
      ) : <div className="spinner-wrapper"><SpinnerLoader /></div>}
    </div>
  );
}

export default ProductByID;
