import React, { useState } from "react";
import logo from "../../assets/LEFTKOAST-LOGO.png";
import ReCAPTCHA from "react-google-recaptcha";
import { useDispatch, useSelector } from "react-redux";
import { setErrorMessage } from "../../redux/slices/errorMessage";
import { setSuccessMessage } from "../../redux/slices/successMessage";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import "./PasswordReset.css";

function PasswordReset() {
  const production = useSelector((state) => state.production.production);
  const [capVal, setCapVal] = useState(null);
  const { token } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const testingURL = `http://127.0.0.1:5000/new-password/${token}`;
  const productionURL = `https://lkp-api.vercel.app/new-password/${token}`;

  const validationSchema = Yup.object().shape({
    new_password: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .required("New Password is required"),
    confirm_password: Yup.string()
      .oneOf([Yup.ref("new_password"), null], "Passwords must match")
      .required("Confirm Password is required"),
  });

  const formik = useFormik({
    initialValues: {
      new_password: "",
      confirm_password: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        const response = await fetch(production ? productionURL : testingURL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            token,
            new_password: values.new_password,
          }),
        });

        if (!response.ok) {
          dispatch(setErrorMessage("Failed to reset password"));
        } else {
          dispatch(setSuccessMessage("Password reset successful"));
          navigate("/signin");
        }
      } catch (error) {
        console.error("Error resetting password:", error.message);
        dispatch(setErrorMessage("Error resetting password"));
      }
    },
  });

  return (
    <div className="PasswordResetRequest">
      <div className="password-reset-request-wrapper">
        <Link to="/">
          <img src={logo} width={150} alt="logo" />
        </Link>
        <form onSubmit={formik.handleSubmit}>
          <h3>Reset Your Password</h3>
          <p>Type in your new password</p>
          <input
            type="password"
            name="new_password"
            onChange={formik.handleChange}
            value={formik.values.new_password}
            placeholder="New Password"
          />
          {formik.touched.new_password && formik.errors.new_password ? (
            <div className="error-message">{formik.errors.new_password}</div>
          ) : null}
          <input
            type="password"
            name="confirm_password"
            onChange={formik.handleChange}
            value={formik.values.confirm_password}
            placeholder="Confirm Password"
          />
          {formik.touched.confirm_password && formik.errors.confirm_password ? (
            <div className="error-message">
              {formik.errors.confirm_password}
            </div>
          ) : null}
          <ReCAPTCHA
            sitekey="6Lc3DQ0qAAAAAGqF6SVufDCzjO5NcryMUpqUTSKQ"
            onChange={(val) => setCapVal(val)}
          />
          <button disabled={!capVal} type="submit" className={!capVal ? "btn-disabled" : ""}>
            SUBMIT
          </button>
        </form>
      </div>
    </div>
  );
}

export default PasswordReset;
